const RESOURCE = '/wp/v2/portfolio_item';

const initialState = {
  portfolioItems: [
    {
      title: 'Dirafrost',
      image: 'dirafrost_straight.webp',
      mobileImage: 'dirafrost_mobile.webp',
      htmlContent:
        "Dirafrost's online aanwezigheid was niet al te best. Om de wereldwijde klanten beter te kunnen bereiken en het ruime portfolio in de kijker te zetten, werd ik gecontacteerd.  Gebruikmakend van het bestaande materiaal, blies ik de website nieuw leven in en kwam ik tot een mooi resultaat.",
    },
    {
      title: 'Betonboringen Verachtert',
      image: 'betonboringen_verachtert_straight.webp',
      mobileImage: 'betonboringen_verachtert_mobile.webp',
      htmlContent: '',
    },
    {
      title: 'Geelse Vriendenclubs',
      image: 'geelse_vriendenclubs_straight.webp',
      mobileImage: 'geelse_vriendenclubs_mobile.webp',
      htmlContent: '',
    },
    {
      title: 'YERA',
      image: 'yera_straight.webp',
      mobileImage: 'yera_mobile.webp',
      htmlContent: '',
    },
    {
      title: 'La Bella Julia',
      image: 'labellajulia_straight.webp',
      mobileImage: 'labellajulia_mobile.webp',
      htmlContent: '',
    },
  ],
  loading: false,
  currentPortfolioItem: null,
  pagination: {
    total: 0,
    currentPage: 1,
    perPage: 100,
    search: '',
  },
};

export const state = () => initialState;

export const mutations = {
  setLoading(state, value = true) {
    state.error = null;
    state.loading = value;
  },
  setPortfolioItems(state, value) {
    state.portfolioItems = value;
    state.loading = false;
  },
  setCurrentPortfolioItem(state, value) {
    state.currentPortfolioItem = value;
    state.loading = false;
  },
  setPagination(state, value) {
    state.pagination = { ...state.pagination, ...value };
    state.pagination.currentPage = parseInt(state.pagination.currentPage);
    state.pagination.total = parseInt(state.pagination.total);
  },
};

export const actions = {
  async get({ commit }, searchValue = '') {
    try {
      commit('setLoading');
      let { data } = await this.$axios.get(
        `${RESOURCE}?order_by=date&search=${searchValue}`,
      );
      commit('setPortfolioItems', data);
    } catch (error) {
      commit('setRequestError', error, { root: true });
      commit('setLoading', false);
    }
  },
  async getPaginated({ commit, state }) {
    const pagination = state.pagination;
    try {
      commit('setLoading');
      const search =
        pagination.search != '' ? `search=${pagination.search}&` : '';
      const perPage = `per_page=${pagination.perPage}&`;
      const page = `page=${pagination.currentPage}`;
      let { data, headers } = await this.$axios.get(
        `${RESOURCE}?order_by=date&${search}${perPage}${page}`,
      );
      commit('setPagination', {
        total: headers['x-wp-total'],
      });
      commit('setPortfolioItems', data);
    } catch (error) {
      commit('setRequestError', error, { root: true });
      commit('setLoading', false);
    }
  },
  async getById({ commit }, searchValue = '') {
    try {
      commit('setLoading');
      let { data } = await this.$axios.get(
        `${RESOURCE}?include[]=${searchValue}`,
      );
      commit('setCurrentPortfolioItem', data[0]);
    } catch (error) {
      commit('setRequestError', error, { root: true });
      commit('setLoading', false);
    }
  },
};

export const getters = {};
