const RESOURCE = '/wp/v2/product';

const initialState = {
  products: [
    {
      title: 'Webapplicaties',
      subtitle: 'Nieuwe ontwikkeling',
      htmlContent:
        'Zit je met taken die zichzelf blijven herhalen of wil je graag die vervelende notitieboek vervangen door een online platform? Dat kan! Door deze processen te digitaliseren spaar je op lange termijn veel tijd en geld uit.',
      image: 'webapps',
    },
    {
      title: 'Website',
      subtitle: 'Nieuwe ontwikkeling',
      htmlContent:
        'Ben je op zoek naar een manier om jezelf beter te vertegenwoordigen op het internet? Met een mooie website zorg je er voor dat je sneller gevonden wordt en maak je een goede indruk bij potentiële klanten.',
      image: 'website',
    },
    {
      title: 'SEO/SEA',
      subtitle: 'Optimalisatie',
      htmlContent:
        'Je website optimaliseren voor zoekmachines is de beste manier om meer bezoekers te trekken. Via de juiste technieken en optimalisatie is het mogelijk je website hoger te doen scoren in de zoekopdrachten.',
      image: 'seo',
    },
  ],
  loading: false,
  currentProduct: null,
  pagination: {
    total: 0,
    currentPage: 1,
    perPage: 100,
    search: '',
  },
};

export const state = () => initialState;

export const mutations = {
  setLoading(state, value = true) {
    state.error = null;
    state.loading = value;
  },
  setProducts(state, value) {
    state.products = value;
    state.loading = false;
  },
  setCurrentProduct(state, value) {
    state.currentProduct = value;
    state.loading = false;
  },
  setPagination(state, value) {
    state.pagination = { ...state.pagination, ...value };
    state.pagination.currentPage = parseInt(state.pagination.currentPage);
    state.pagination.total = parseInt(state.pagination.total);
  },
};

export const actions = {
  async get({ commit }, searchValue = '') {
    try {
      commit('setLoading');
      let { data } = await this.$axios.get(
        `${RESOURCE}?order_by=date&search=${searchValue}`,
      );
      commit('setProducts', data);
    } catch (error) {
      commit('setRequestError', error, { root: true });
      commit('setLoading', false);
    }
  },
  async getPaginated({ commit, state }) {
    const pagination = state.pagination;
    try {
      commit('setLoading');
      const search =
        pagination.search != '' ? `search=${pagination.search}&` : '';
      const perPage = `per_page=${pagination.perPage}&`;
      const page = `page=${pagination.currentPage}`;
      let { data, headers } = await this.$axios.get(
        `${RESOURCE}?order_by=date&${search}${perPage}${page}`,
      );
      commit('setPagination', {
        total: headers['x-wp-total'],
      });
      commit('setProducts', data);
    } catch (error) {
      commit('setRequestError', error, { root: true });
      commit('setLoading', false);
    }
  },
  async getById({ commit }, searchValue = '') {
    try {
      commit('setLoading');
      let { data } = await this.$axios.get(
        `${RESOURCE}?include[]=${searchValue}`,
      );
      commit('setCurrentProduct', data[0]);
    } catch (error) {
      commit('setRequestError', error, { root: true });
      commit('setLoading', false);
    }
  },
};

export const getters = {};
