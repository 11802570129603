const RESOURCE = '/wp/v2/service';

const initialState = {
  services: [
    {
      title: 'Op maat gemaakt',
      image: 'custom',
      htmlContent:
        'Alles is aangepast naar jouw persoonlijke wensen. Een reeds bestaande huisstijl opnemen in het product is geen probleem.',
    },
    {
      title: 'Persoonlijke aanpak',
      image: 'personal',
      htmlContent:
        'Na een goede analyse van het gewenste product en in gezamenlijk overleg komen we tot het beste resultaat.',
    },
    {
      title: 'Naar ieders budget',
      image: 'budget',
      htmlContent:
        'Bij mij is een website of applicatie steeds betaalbaar. Je hoeft geen fortuin neer te leggen om aanwezig te zijn op het online web.',
    },
    {
      title: 'Blijvende ondersteuning',
      image: 'support',
      htmlContent:
        'Het stopt niet bij een afgeleverd product. Zit je na de oplevering met vragen of heb je hulp nodig bij het gebruik, aarzel dan niet om mij te contacteren.',
    },
    {
      title: 'Hoge score in zoekresultaten',
      image: 'seo',
      htmlContent:
        'Meer bezoekers naar je websites krijgen? Dat kan! Aan de hand van de juiste technieken (SEO) zal je website hoger komen te staan in de zoekresultaten van zoekmachines zoals Google.',
    },
    {
      title: 'Responsief design',
      image: 'responsive',
      htmlContent:
        'Wil je jouw website zowel op pc, smartphone en tablet kunnen gebruiken? Geen enkel probleem, alle producten zijn standaard volledig responsief en dus beschikbaar op elk toestel.',
    },
  ],
  loading: false,
  currentService: null,
  pagination: {
    total: 0,
    currentPage: 1,
    perPage: 100,
    search: '',
  },
};

export const state = () => initialState;

export const mutations = {
  setLoading(state, value = true) {
    state.error = null;
    state.loading = value;
  },
  setServices(state, value) {
    state.services = value;
    state.loading = false;
  },
  setCurrentService(state, value) {
    state.currentService = value;
    state.loading = false;
  },
  setPagination(state, value) {
    state.pagination = { ...state.pagination, ...value };
    state.pagination.currentPage = parseInt(state.pagination.currentPage);
    state.pagination.total = parseInt(state.pagination.total);
  },
};

export const actions = {
  async get({ commit }, searchValue = '') {
    try {
      commit('setLoading');
      let { data } = await this.$axios.get(
        `${RESOURCE}?order_by=date&search=${searchValue}`,
      );
      commit('setServices', data);
    } catch (error) {
      commit('setRequestError', error, { root: true });
      commit('setLoading', false);
    }
  },
  async getPaginated({ commit, state }) {
    const pagination = state.pagination;
    try {
      commit('setLoading');
      const search =
        pagination.search != '' ? `search=${pagination.search}&` : '';
      const perPage = `per_page=${pagination.perPage}&`;
      const page = `page=${pagination.currentPage}`;
      let { data, headers } = await this.$axios.get(
        `${RESOURCE}?order_by=date&${search}${perPage}${page}`,
      );
      commit('setPagination', {
        total: headers['x-wp-total'],
      });
      commit('setServices', data);
    } catch (error) {
      commit('setRequestError', error, { root: true });
      commit('setLoading', false);
    }
  },
  async getById({ commit }, searchValue = '') {
    try {
      commit('setLoading');
      let { data } = await this.$axios.get(
        `${RESOURCE}?include[]=${searchValue}`,
      );
      commit('setCurrentService', data[0]);
    } catch (error) {
      commit('setRequestError', error, { root: true });
      commit('setLoading', false);
    }
  },
};

export const getters = {};
