const RESOURCE = '/wp/v2/review';

const initialState = {
  reviews: [
    {
      htmlContent:
        'Een professionele en functionele website op maat, voorzien van een ‘fruitig’ en hedendaags design, daar waren we naar op zoek. Dankuwel Joren, voor het mooie en gebruiksvriendelijke eindresultaat en de aangename samenwerking.',
      name: 'Veronique Thielens - Marketing manager',
      company: 'Dirafrost',
    },
    {
      htmlContent:
        'De samenwerking met Joren verliep zeer vlot, hij kon een website maken die voldeed aan al onze eisen. Ook indien we achteraf nog zaken wilden veranderen, sprong Joren hier snel op in. Kortom: YERA is zeer blij met het resultaat van de website en hoe de samenwerking is verlopen.',
      name: 'Elise Meersseman - Vice president',
      company: 'YERA',
    },
  ],
  loading: false,
  currentReview: null,
  pagination: {
    total: 0,
    currentPage: 1,
    perPage: 100,
    search: '',
  },
};

export const state = () => initialState;

export const mutations = {
  setLoading(state, value = true) {
    state.error = null;
    state.loading = value;
  },
  setReviews(state, value) {
    state.reviews = value;
    state.loading = false;
  },
  setCurrentReview(state, value) {
    state.currentReview = value;
    state.loading = false;
  },
  setPagination(state, value) {
    state.pagination = { ...state.pagination, ...value };
    state.pagination.currentPage = parseInt(state.pagination.currentPage);
    state.pagination.total = parseInt(state.pagination.total);
  },
};

export const actions = {
  async get({ commit }, searchValue = '') {
    try {
      commit('setLoading');
      let { data } = await this.$axios.get(
        `${RESOURCE}?order_by=date&search=${searchValue}`,
      );
      commit('setReviews', data);
    } catch (error) {
      commit('setRequestError', error, { root: true });
      commit('setLoading', false);
    }
  },
  async getPaginated({ commit, state }) {
    const pagination = state.pagination;
    try {
      commit('setLoading');
      const search =
        pagination.search != '' ? `search=${pagination.search}&` : '';
      const perPage = `per_page=${pagination.perPage}&`;
      const page = `page=${pagination.currentPage}`;
      let { data, headers } = await this.$axios.get(
        `${RESOURCE}?order_by=date&${search}${perPage}${page}`,
      );
      commit('setPagination', {
        total: headers['x-wp-total'],
      });
      commit('setReviews', data);
    } catch (error) {
      commit('setRequestError', error, { root: true });
      commit('setLoading', false);
    }
  },
  async getById({ commit }, searchValue = '') {
    try {
      commit('setLoading');
      let { data } = await this.$axios.get(
        `${RESOURCE}?include[]=${searchValue}`,
      );
      commit('setCurrentReview', data[0]);
    } catch (error) {
      commit('setRequestError', error, { root: true });
      commit('setLoading', false);
    }
  },
};

export const getters = {};
